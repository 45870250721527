import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders a product price. This relies on an `<product-element>` in a slot for data.
 */
class ProductPrice extends HTMLElement implements CustomElement {
  readonly dataset!: {
    /**
     * Currency code
     */
    currencyCode: string;

    /**
     * Currency Symbol (e.g., $)
     */
    currencySymbol: string;

    /**
     * Displays additional discount information (e.g., FREE)
     */
    discountCopy: string;

    /**
     * Hides price if set to "true"
     */
    hidePrice: string;
  };

  private onProductVariantOptionChangedBound = this.onProductVariantOptionChanged.bind(this);

  /**
   * The product state associated with this element.
   *
   * @deprecated refactor to query the dom for state every time
   */
  private product: Partial<Product>;
  private onDOMContentLoadedBound = this.onDOMContentLoaded.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    addEventListener('product-variant-option-changed', this.onProductVariantOptionChangedBound);

    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      setTimeout(this.onDOMContentLoadedBound);
    } else {
      addEventListener('DOMContentLoaded', this.onDOMContentLoadedBound);
    }
  }

  public disconnectedCallback() {
    removeEventListener('DOMContentLoaded', this.onDOMContentLoadedBound);
    removeEventListener('product-variant-option-changed', this.onProductVariantOptionChangedBound);
  }

  private onDOMContentLoaded(_event: Event) {
    this.updateProductFromDOM();
    this.render();
  }

  private onProductVariantOptionChanged(event: WindowEventMap['product-variant-option-changed']) {
    // This event may be fired by an element attached to a different product.
    // Our component's slot may not even be fully parsed at the time of receiving
    // this event if it was targeted at a different component/product.
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    if (this.product && event.detail.id === this.product.id) {
      const productEl = this.querySelector('product-element');
      // productEl is inside of a slot, so may not always be available
      if (productEl) {
        productEl.dataset.selectedVariantId = event.detail.selected_variant_id.toString();
        this.updateProductFromDOM();
        this.render();
      }
    }
  }

  /**
   * @todo refactor, query the dom when rendering instead of trying to stash state in a class
   * property, the dom is the state
   */
  private updateProductFromDOM() {
    // productEl is inside of a slot, so may not always be available
    const productElement = this.querySelector('product-element');
    if (!productElement) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      this.product = undefined;
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-deprecated
    this.product = {};
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    this.product.id = parseInt(productElement.dataset.id, 10);
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    this.product.selected_variant_id = parseInt(productElement.dataset.selectedVariantId, 10);
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    this.product.type = productElement.dataset.type;

    // eslint-disable-next-line @typescript-eslint/no-deprecated
    this.product.variants = [];

    // TODO: look into why this sometimes fails to find any variant elements, it should always
    // find at least 1

    const variantElements = productElement.querySelectorAll('variant-element');
    for (const variantElement of variantElements) {
      const variant = <ProductVariant>{};
      variant.id = parseInt(variantElement.dataset.id, 10);
      variant.price = parseInt(variantElement.dataset.price, 10);
      variant.compare_at_price = parseInt(variantElement.dataset.compareAtPrice, 10) || 0;
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      this.product.variants.push(variant);
    }
  }

  /**
   * Updates the DOM based on the element's state. How this element renders is based on the type of
   * the associated product.
   *
   * Configurable bundles are handled the same way as configurable products.
   */
  private render() {
    // The render function may be sometimes called when the slot element is not yet filled
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    if (!this.product) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-deprecated
    if (this.product.type === 'configurable-bundle') {
      this.renderConfigurableProduct();
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    } else if (Array.isArray(this.product.variants) && this.product.variants.length > 1) {
      this.renderConfigurableProduct();
    } else {
      this.renderSimpleProduct();
    }

    // Render discount copy
    if (this.dataset.discountCopy) {
      const discountEl = this.shadowRoot.querySelector('span.discount-copy');
      if (discountEl) {
        discountEl.textContent = this.dataset.discountCopy;
      }
    }

    // Hide <price-element> if value is "true"
    if (this.dataset.hidePrice === 'true') {
      const priceEl = this.shadowRoot.getElementById('price');
      if (priceEl) {
        priceEl.dataset.hide = 'true';
      }
    }
  }

  /**
   * @todo investigate why this sometimes finds no variants when running, one issue is that render
   * defaults to calling this function when there are 1 or fewer variants, and sometimes we happen
   * to be in the 0 case, but this function assumes at least 1
   */
  private renderSimpleProduct() {
    const compareAtPriceEl = this.shadowRoot.getElementById('compare-at-price');
    // TODO: sometimes compare at price is not found so we have to check if it exists. this is
    // weird because it is defined by id in the shadow dom template so there is no reason for it to
    // ever not be found. might be related to certain browsers behavior when parsing child elements.

    try {
      compareAtPriceEl.dataset.showCode = 'false';
      compareAtPriceEl.dataset.currencyCode = this.dataset.currencyCode;
      compareAtPriceEl.dataset.currencySymbol = this.dataset.currencySymbol;
    } catch (error) {
      // The problem mentioned in many TODOs in this file is hopefully fixed now
      // I'm leaving this here for some time to see if we still get this error in production
      // If not I'll clean up all remaining TODO and defensive checks in this file
      console.error(error);
    }

    // A simple product is a product with only one variant. The variant should always exist, so we
    // should be able to safely access the first item in the variants array.

    // TODO: the variant does not always exist. the above comment is incorrect. perhaps it does not
    // necessarily exist at the time the function runs. i have added mitigation that is defensive
    // and defaults to not showing in this case instead of erring so that rendering is unaffected.

    if (compareAtPriceEl) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      if (Array.isArray(this.product?.variants) && this.product.variants.length > 0 &&
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        typeof this.product.variants[0] === 'object' && this.product.variants[0].compare_at_price) {
        compareAtPriceEl.dataset.hide = 'false';
        compareAtPriceEl.dataset.ariaLabelPrefix = 'Regular price:';
      } else {
        compareAtPriceEl.dataset.hide = 'true';
        compareAtPriceEl.dataset.ariaLabelPrefix = '';
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-deprecated
    const compareAtPrice = this.product?.variants?.[0]?.compare_at_price;

    if (compareAtPriceEl && typeof compareAtPrice === 'number') {
      compareAtPriceEl.dataset.cents = compareAtPrice.toString();
    }

    const priceEl = this.shadowRoot.getElementById('price');
    // TODO: there is a bug here where the price element is sometimes not found
    if (priceEl) {
      priceEl.dataset.currencyCode = this.dataset.currencyCode;
      priceEl.dataset.currencySymbol = this.dataset.currencySymbol;
    }

    // eslint-disable-next-line @typescript-eslint/no-deprecated
    const price = this.product?.variants?.[0]?.price;

    // TODO: there is a bug here where the price element is sometimes not found
    // TODO: there is a bug here where price is not an integer

    if (priceEl && Number.isInteger(price)) {
      priceEl.dataset.cents = price.toString();

      // eslint-disable-next-line @typescript-eslint/no-deprecated
      if (Array.isArray(this.product?.variants) && this.product.variants.length > 0 &&
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        typeof this.product.variants[0] === 'object' && this.product.variants[0].compare_at_price) {
        priceEl.dataset.ariaLabelPrefix = 'Sale price:';
      } else {
        priceEl.dataset.ariaLabelPrefix = 'Price:';
      }
    }
  }

  private renderConfigurableProduct() {
    const compareAtPriceEl = this.shadowRoot.getElementById('compare-at-price');
    if (!compareAtPriceEl) {
      // DOM is not loaded
      return;
    }

    compareAtPriceEl.dataset.showCode = 'false';
    compareAtPriceEl.dataset.currencyCode = this.dataset.currencyCode;
    compareAtPriceEl.dataset.currencySymbol = this.dataset.currencySymbol;

    // eslint-disable-next-line @typescript-eslint/no-deprecated
    const selectedVariant = this.product.variants.find(variant =>
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      variant.id === this.product.selected_variant_id);

    // This check is important. When someone visits a pdp with a variant parameter, and the variant
    // parameter is invalid, then the selected variant id is not initialized correctly, and as a
    // result, there is no way to figure out which variant is selected. This happens routinely
    // when someone clicked on an invalid or out of date link. This happens routinely because
    // marketing will advertise a page with an explicit variant, then later make catalog changes
    // such as replacing the variants of a product, after which the ads are wrong. The ads point to
    // the correct PDP, but the variant parameter is now wrong.

    // TODO: the code that initializes the product-price element should be able to react to
    // an invalid variant parameter by falling back to the default variant as selected and still
    // properly initialize.

    if (!selectedVariant) {
      console.warn('selected variant not found');
      return;
    }

    if (selectedVariant.compare_at_price) {
      compareAtPriceEl.dataset.hide = 'false';
      compareAtPriceEl.dataset.ariaLabelPrefix = 'Regular price:';
    } else {
      compareAtPriceEl.dataset.hide = 'true';
      compareAtPriceEl.dataset.ariaLabelPrefix = '';
    }

    const compareAtPrice = selectedVariant.compare_at_price;
    compareAtPriceEl.dataset.cents = compareAtPrice.toString();

    const priceEl = this.shadowRoot.getElementById('price');
    const price = selectedVariant.price;
    priceEl.dataset.currencyCode = this.dataset.currencyCode;
    priceEl.dataset.currencySymbol = this.dataset.currencySymbol;
    priceEl.dataset.cents = price.toString();

    if (selectedVariant.compare_at_price) {
      priceEl.dataset.ariaLabelPrefix = 'Sale price:';
    } else {
      priceEl.dataset.ariaLabelPrefix = 'Price:';
    }
  }
}

/**
 * Represents a Shopify product variant
 */
interface ProductVariant {
  /**
   * Whether the variant is in stock
   */
  available: boolean;

  /**
   * Whether the variant is on backorder
   */
  backorder: string;

  /**
   * The MSRP, or original sales price before discounts, per unit
   */
  compare_at_price: number;

  /**
   * The currency code associated with the price
   */
  currency_code: string;

  /**
   * @todo clarify what this means
   */
  hide_backorder_in_collection: string;

  /**
   * Variant id
   *
   * @example 12345568943
   */
  id: number;

  /**
   * The value of the variant for the first product option. If there's no first product option, then
   * nil is returned.
   */
  option1: string;

  /**
   * The value of the variant for the second product option. If there's no first product option,
   * then nil is returned.
   */
  option2: string;

  /**
   * The value of the variant for the third product option. If there's no first product option, then
   * nil is returned.
   */
  option3: string;

  /**
   * The price of the variant
   *
   * @todo clarify, is this in cents?
   */
  price: number;

  /**
   * Whether this variant is the currently selected variant out of all the variants for the product
   */
  selected: boolean;

  /**
   * The Stock Keeping Unit associated with the variant. This is another kind of product id.
   */
  sku: string;

  /**
   * The title of the variant.
   */
  title: string;
}

interface Product {
  /**
   * Product inventory availability
   */
  available: boolean;

  /**
   * Product award image
   */
  award_badge_image: string;

  /**
   * Product award tag
   */
  award_tag: string;

  /**
   * Product badge type
   */
  badge_type: '' | 'best-seller' | 'favorite' | 'ideal-for-you' | 'new' | 'travel-friendly';

  /**
   * Product main image
   */
  hover_image: string;

  /**
   * Shopify product id
   */
  id: number;

  /**
   * Product main image loading method
   */
  lazyload: boolean;

  /**
   * Product main image
   */
  main_image: string;

  /**
   * Product metafields discount code (used to promote public discount codes)
   */
  metafields_discount_code: string;

  /**
   * Product metafields discount override to use one-off messages instead of generated text
   */
  metafields_discount_override: string;

  /**
   * Product metafields discount percentage (used to render public discount code prices)
   */
  metafields_discount_percentage: number;

  /**
   * Product metafields subtitle
   */
  metafields_subtitle: string;

  /**
   * Product metafields title
   */
  metafields_title: string;

  option_groups: {
    name: string;
    options: {
      value: string;
      variants: Partial<{
        /**
         * Whether the variant has inventory in stock and is available for sale.
         */
        available: boolean;

        backorder_message: string;

        compare_at_price: number;

        customAttributes: {
          /**
           * Key or name of the attribute.
           */
          key: string;

          /**
           * Value of the attribute.
           */
          value: string;
        }[];

        hideBackorderInCollection: string;

        /**
         * Variant id
         */
        id: number;

        price: number;

        sku: string;

        title: string;
      }>[];
    }[];
    position?: number;
    selected_option: string;
  }[];

  /**
   * Product yotpo reviews average value
   */
  reviews_average: number;

  /**
   * Product yotpo reviews count
   */
  reviews_count: number;

  /**
   * Selected variant id
   */
  selected_variant_id: number;

  /**
   * Product sku
   */
  sku: string;

  /**
   * Product tags
   */
  tags: string;

  /**
   * Product title
   */
  title: string;

  /**
   * Product type
   *
   * @example "hair-care"
   */
  type: string;

  /**
   * Shopify product url
   */
  url: string;

  /**
   * Represents a product with multiple variants that is part of a value set. This value is used to
   * inform any subscribers consuming the product-variant-option-changed event that a product with
   * an ID not matching the parent product still requires it's DOM data indicating the actively
   * selected product.
   *
   * @todo this should be removed
   *
   * @deprecated
   */
  value_set_parent_id: number;

  /**
   * A list of the product's variants with some information about variant
   */
  variants: {
    /**
     * Whether the variant is in stock
     */
    available: boolean;

    /**
     * Whether the variant is on backorder
     */
    backorder: string;

    /**
     * The MSRP, or original sales price before discounts, per unit
     */
    compare_at_price: number;

    /**
     * The currency code associated with the price
     */
    currency_code: string;

    /**
     * @todo clarify what this means
     */
    hide_backorder_in_collection: string;

    /**
     * Variant id
     *
     * @example 12345568943
     */
    id: number;

    /**
     * The value of the variant for the first product option. If there's no first product option,
     * then nil is returned.
     */
    option1: string;

    /**
     * The value of the variant for the second product option. If there's no first product option,
     * then nil is returned.
     */
    option2: string;

    /**
     * The value of the variant for the third product option. If there's no first product option,
     * then nil is returned.
     */
    option3: string;

    /**
     * The price of the variant
     *
     * @todo clarify, is this in cents?
     */
    price: number;

    /**
     * Whether this variant is the currently selected variant out of all the variants for the
     * product
     */
    selected: boolean;

    /**
     * The Stock Keeping Unit associated with the variant. This is another kind of product id.
     */
    sku: string;

    /**
     * The title of the variant.
     */
    title: string;
  }[];
}

declare global {
  interface HTMLElementTagNameMap {
    'product-price': ProductPrice;
  }
}

if (!customElements.get('product-price')) {
  customElements.define('product-price', ProductPrice);
}
